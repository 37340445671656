<template>
    <div>
        <h1>Instrukcje obsługi</h1>  
        <p>Dostępne w krótce</p>
    </div>
</template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
    BFormCheckbox, BDropdown, BDropdownItem, BFormSelect, BFormSelectOption,
    BFormDatepicker,
    BFormGroup,
    BPagination
  } from 'bootstrap-vue'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  
  export default {
    components: {
      BCard,
      BBadge,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BFormCheckbox,
      BDropdown,
      BDropdownItem,
      BFormSelect,
      BFormSelectOption,
      BFormDatepicker,
      BFormGroup,
      BPagination
    },
    data() {
      return {
        fields: [
          {
            key: 'lp',
            label: 'L.p.',
            sortable: false,
          },
          {
            key: 'id',
            label: 'ID',
            sortable: false,
          },
          {
            key: 'name',
            label: 'Nazwa',
            sortable: false,
          },
          {
            key: 'location',
            label: 'Adres',
            sortable: false,
          },
          {
            key: 'service_date',
            label: 'Data serwisu',
            sortable: false,
          },
          {
            key: 'installation_date',
            label: 'Data instalacji',
            sortable: false,
          },
          {
            key: 'actions',
            label: 'akcje',
          },
        ],
        items: [],
        search: '',
        limit: 20,
        page: 1,
        items_count: 0
      }
    },
    watch:{
      search: function (value){
        this.fetchFilters()
      },
      page: function(value){
        this.fetchFilters()
      }
    },
    mounted() {
      this.fetchFilters()
      this.fromDate = new Date().toISOString().slice(0, 10)
      this.toDate = new Date().toISOString().slice(0, 10)
    },
    created(){
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Service`, config).then(response => {
        this.services = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    methods: {
      fetchFilters() {
        const queryParams = {
          limit: this.limit,
          page: this.page,
        }
        if(this.search){
          queryParams.query = this.search
        }
        const searchParams = new URLSearchParams(queryParams)
        const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
        axios.get(`${process.env.VUE_APP_API_URL}unactive-filters?${searchParams.toString()}`, config).then(response => {
          this.items = response.data.items
          this.items_count = response.data.count
          this.items.map((item, index) => {
            item.lp = index+1
            item.location = `${item.city} ul. ${item.address}`
          })
        }).catch(error => {
          console.log(error)
        })
      },
      deleteFilter(id) {
        axios.delete(`${process.env.VUE_APP_API_URL}Filter/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }).then(() => {
          Swal.fire(
            'Pomyślnie usunięto serwis',
            '',
            'success',
          )
          this.fetchFilters()
        }).catch(() => {
          Swal.fire(
            'Coś poszło nie tak!',
            '',
            'error',
          )
        })
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  